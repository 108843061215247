import React, { useEffect, useState } from 'react';
import gql from 'not-graphql-tag';
import { useTranslation } from '../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  Box,
  Paper,
  Card,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/';
import PaperBox from '../components/utility/paper-box';
import ROUTES from '../routes';
import { navigate, useLocation } from '@reach/router';
import PGEButton from '../components/buttons';
import * as queryString from 'query-string';
import Alerts from '../components/alerts/index';
import colors from '../themes/main-colors';
import { getFromStorage, setInStorage } from '../util/storage-utils';
import apolloClient from '../lib/apolloClient';
import { VerifyAddPersonAccessTokenResponse } from '../__generated__/pge-types';
import Backdrop from '../components/backdrop';
import useWrapWithLoader from '../hooks/useWrapWithLoading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comfirmEmailPage: {
      margin: '70px auto',
      color: colors.noirBlur,
      [theme.breakpoints.down('xs')]: {
        margin: '16px',
      },
      '& h2': {
        fontWeight: 300,
        margin: '-15px 0 0',
      },
    },
    doneBtnHolder: {
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  }),
);

type QueryParams = {
  token: string;
  email: string;
  reject: string;
};

export const AddPersonVerifyToken = () => {
  const location = useLocation();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const queryParams = queryString.parse(location.search) as QueryParams;
  const { token, email, reject } = queryParams;
  const { wrapWithLoader } = useWrapWithLoader();
  const [status, setStatus] = useState<string>(
    t('LOADING_MESSAGE_FOR_VERIFY_EMAIL'),
  );
  useEffect(() => {
    // API call for verification
    void verifyUserToken();
  }, []);
  const verifyUserToken = wrapWithLoader(async () => {
    // API call for verification

    const verifyAddPersonAccessToken = gql`
      mutation verifyAddPersonAccessToken(
        $payload: VerifyAddPersonAccessTokenRequest!
      ) {
        verifyAddPersonAccessToken(payload: $payload) {
          status
          errorReason
        }
      }
    `;

    const { data } = await apolloClient.mutate<{
      verifyAddPersonAccessToken: VerifyAddPersonAccessTokenResponse;
    }>({
      mutation: verifyAddPersonAccessToken,
      variables: {
        payload: {
          accessToken: token,
          emailAddress: email,
          shouldVerify: !reject, // for reject request, we send shouldVerify as false
        },
      },
    });

    if (data?.verifyAddPersonAccessToken?.status) {
      setStatus(reject ? t('REJECT_USER_MESSAGE') : t('APPROVE_USER_MESSAGE'));
    } else {
      setStatus(t('VALIDATE_USER_LINK_EXPIRED'));
    }
  });

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={11} sm={8} md={6}>
          <Paper component={'main'} className={classes.comfirmEmailPage}>
            <Card>
              <Alerts
                disableColorOverride={true}
                isOpen
                severity={'success'}
                variant={'outlined'}
                title={''}
                message={
                  <>
                    <Grid container spacing={2}>
                      <Grid>
                        <Typography component="span">{status}</Typography>
                      </Grid>
                    </Grid>
                    <Grid className={classes.doneBtnHolder}>
                      <PGEButton
                        onClick={async () => {
                          await navigate(ROUTES.SIGN_IN);
                        }}
                      >
                        <Typography variant={'h4'} noWrap>
                          {t('DONE')}
                        </Typography>
                      </PGEButton>
                    </Grid>
                  </>
                }
              />
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPersonVerifyToken;
