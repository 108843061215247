import { Collapse, Theme, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { forwardRef } from 'react';
import colors from '../../themes/main-colors';

export type AlertSeverity = 'error' | 'warning' | 'info' | 'success';

export type AlertVariant = 'filled' | 'outlined' | 'standard';

interface AlertProps {
  action?: JSX.Element;
  severity?: AlertSeverity;
  variant?: AlertVariant;
  title?: React.ReactNode;
  message?: React.ReactNode;
  isOpen?: boolean;
  icon?: JSX.Element;
  onClose?: () => any;
  disableColorOverride?: boolean;
  style?: React.CSSProperties;
}
type AlertClasses = {
  success: string;
  error: string;
  warning: string;
  info: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  standardSuccess: {
    backgroundColor: colors.shoreGreen,
  },
  outlinedSuccess: {
    backgroundColor: colors.shoreGreen,
  },
  filledSuccess: {
    backgroundColor: colors.shoreGreen,
  },
  standardWarning: {
    backgroundColor: colors.warning,
  },
  outlinedWarning: {
    backgroundColor: colors.warning,
  },
  filledWarning: {
    backgroundColor: colors.warning,
  },
  standardError: {
    backgroundColor: colors.roseRed,
  },
  outlinedError: {
    backgroundColor: colors.roseRed,
  },
  filledError: {
    backgroundColor: colors.roseRed,
  },
  standardInfo: {
    backgroundColor: colors.info,
  },
  outlinedInfo: {
    backgroundColor: colors.info,
  },
  filledInfo: {
    backgroundColor: colors.info,
  },
}));

const Alerts = forwardRef<HTMLElement, AlertProps>((props, ref) => {
  const classes = useStyles();
  const alertClasses: AlertClasses = {
    success: classes.filledSuccess,
    error: classes.filledError,
    warning: classes.filledWarning,
    info: classes.filledInfo,
  };
  const alertClass = alertClasses[props.severity || 'info'];
  return (
    <Collapse in={props.isOpen}>
      <Alert
        className={props.disableColorOverride ? undefined : alertClass}
        action={props.action}
        onClose={props?.onClose}
        ref={ref}
        severity={props.severity}
        variant={props.variant}
        iconMapping={{
          success: props.icon || <CheckCircleIcon fontSize="inherit" />,
          error: props.icon || <ErrorIcon fontSize="inherit" />,
          warning: props.icon || <WarningIcon fontSize="inherit" />,
          info: props.icon || <InfoIcon fontSize="inherit" />,
        }}
        style={props.style}
      >
        <AlertTitle>{props.title}</AlertTitle>
        {props.message}
      </Alert>
    </Collapse>
  );
});

export default Alerts;
